import React, {useState, useEffect} from 'react'
import { useSelector,useDispatch } from "react-redux";
import {get_shortDescription, getGroup_CustomTag,group_Unities} from '../../functions/functions_dev'
import { Link, useLocation, useHistory } from "react-router-dom";
import $ from 'jquery';
import arrowLeftSvg from '../../assets/images/arrow-left.svg';

function onScrollChangeHeaderBg() {
  window.onscroll = function() {
    const header = document.querySelector(".main__second__header__emprendimiento");
    header.style.transition = ".5s ease";
    header.style.backgroundColor = (document.body.scrollTop > 956 
      || document.documentElement.scrollTop > 956)
          ?  "#6e746e" : "#6e746e85";
  }
}
    
export const Header = ({onScrollBg}) => {
  const settings = useSelector((store) => store.settings);
  const { data } = useSelector((store) => store.developments.development);
  const unidad = useSelector((store) => store.properties.property).data;
  const unities = useSelector((store) => store.developments.units_development).data;
  const [open, setOpen] = useState(false);
  
  const [group,setGroup] = useState()
  const [load,setLoad] = useState(false)

  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const viewCur = curLocation ? curLocation.split("/")[0] : ''
  const checkView = (location,view) => {
    console.log(view,viewCur)
      if(viewCur){
        return (viewCur.toLowerCase() === view.toLowerCase() ? true : false)
      }
      return false
  }

  const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 200, behavior: 'smooth'});
  }

  const existBlueprint = () => {
    if(unidad){
      if(unidad.photos.find(element => element.is_blueprint))  return true
      return false
    }
  }

  // const image = $('.image-logo-development')

  useEffect(() => {
    if(unities){
        setGroup(group_Unities(unities.objects))
        setLoad(true)
    }
    if(onScrollBg) {
      onScrollChangeHeaderBg()
    }
  },[unities])


  return (
    <>
      { unidad && checkView(curLocation,"unidad") ? 
        <div className="header__top bg-light-grey d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12 m-auto d-flex justify-content-lg-end justify-content-between align-items-center position-relative">
                <p className="nh-roman header__emprendimiento__title m-0">Un desarrollo de MANCINI Propiedades</p>
                <div className="button__container">
                    <Link className={`btn-link-wrap text-white ${unidad ? '' : 'd-none' }`} to={"/"}>
                      <button className="button__emprendimiento nh-medium text-uppercase d-flex justif-content-center align-items-center">
                        <img src={arrowLeftSvg} className="icon-arrow-left" alt="Arrow left icon" />
                        Volver
                      </button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div> : '' }
      <div className={`main__second__header__emprendimiento d-flex justify-content-center align-items-center ${ !unidad && !checkView(curLocation,"unidad") ? 'bg-dark-grey' : 'bg-dark-transparent'}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 m-auto d-flex justify-content-lg-between justify-content-center position-relative">
              <div className={`${unidad && checkView(curLocation,"unidad") ? 'col-6 col-lg-3' : 'col-6 col-lg-3'}`}>
                <img className="image-logo" alt="Eleven" src={settings.base_url + "img/logos/eleven-logo-white.svg"}  />
              </div>
              { !unidad && !checkView(curLocation,"unidad") ? 
                <div className="col-6 d-none d-lg-block">
                  <nav className="col-6 d-lg-flex d-none justify-content-between align-items-center w-100 h-100">
                    <ul className="d-lg-flex flex-row justify-content-end m-0 w-100">
                      <li className="ms-5">
                          <a style={{margin: "0", cursor: "pointer"}} className="tab nh-roman text-uppercase" onClick={(e) => scrollTo("proyectos") + e.preventDefault() + setOpen(false)}>{checkView(curLocation,"unidad") ? "DESCRIPCIÓN" : "PROYECTO"}</a>
                      </li>
                      { group && unities ?
                        <li className={"ms-5 " + (!checkView(curLocation,"unidad") && unities.meta.total_count > 0 && group.length > 0 ? '': ' d-none ')}>
                            <a style={{margin: "0", cursor: "pointer"}} className="tab nh-roman text-uppercase" onClick={(e) => scrollTo("unidades") + e.preventDefault() + setOpen(false)}>Unidades</a>
                        </li> : '' }
                      <li className={"ms-5 " + (checkView(curLocation,"unidad") && existBlueprint() ? ' d-block' : ' d-none ')}>
                          <a style={{margin: "0", cursor: "pointer"}} className="tab nh-roman text-uppercase" onClick={(e) => scrollTo("planos") + e.preventDefault() + setOpen(false)}>Planos</a>
                      </li>
                      <li className="ms-5">
                          <a style={{margin: "0", cursor: "pointer"}} className="tab nh-roman text-uppercase" onClick={(e) => scrollTo("ubicacion") + e.preventDefault() + setOpen(false)}>Ubicación</a>
                      </li>
                      <li className="ms-5">
                          <a style={{margin: "0", cursor: "pointer"}} className="tab nh-roman text-uppercase" onClick={(e) => scrollTo("contacto") + e.preventDefault() + setOpen(false)}>Contacto</a>
                      </li>
                    </ul>
                  </nav>
                </div> : '' }
              <div className={`ig-wrap text-right ${unidad && checkView(curLocation,"unidad") ? 'col-6 col-lg-3' : 'col-6 col-lg-3'}`}>
                <Link to={settings.social.instagram}>
                  <i className="icon-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
