import { useSelector } from "react-redux";
import {get_shortDescription, getGroup_CustomTag} from "../../functions/functions_dev"
import imageDetails from '../../assets/images/edificio_nosotros.png'
import dblCircle from '../../assets/images/circles_bg_aera.svg';
import imageDetails2 from '../../assets/images/slide-3-desk3.png';
import circleBg from '../../assets/images/elipse_bg_emprendimiento.svg';
import elevenLogoSvg from '../../assets/images/11E-logo.svg';
import arrowRightSvg from '../../assets/images/arrow-right.svg';

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

  
  // Render
  return data ? (
    <>
      <div className="proyect-container container">
        <div className="row">
          <div className="col-12 position-relative text-container">
            <h2 className="nh-medium text-center">Descripción</h2>
          </div>
        </div>
        <div className="row first-row">
          <div className="col-12 col-lg-6 px-0 position-relative">
            <div className="text-content nh-roman">
              {get_shortDescription(data.description).photos[0]}
            </div>
            <img src={arrowRightSvg} alt="Arrow right icon" className="icon-arrow-right"/>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <img src={data.photos[0] ? data.photos[0].original : ''} className="project-image-1" alt="Proyect image" />
          </div>
        </div>
        <div className="row second-row">
          <div className="col-12 col-lg-6 px-0">
            <img src={data.photos[1] ? data.photos[1].original : ''} className="project-image-2" alt="Proyect image" />
          </div>
          <div className="col-12 col-lg-6 px-0 position-relative d-flex flex-column justify-content-center align-items-center">
            <img src={elevenLogoSvg} alt="Eleven" className="eleven-logo"/>
            <div className="text-content nh-roman">
              {get_shortDescription(data.description).photos[1]}
            </div>
          </div>
        </div>
        <div className="row third-row">
          <div className="col-12 col-lg-6 px-0 position-relative">
            <div className="text-content nh-roman">
              {get_shortDescription(data.description).photos[2]}
            </div>
            <img src={arrowRightSvg} alt="Arrow right icon" className="icon-arrow-right"/>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <img src={data.photos[2] ? data.photos[2].original : ''} className="project-image-3" alt="Proyect image" />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;


