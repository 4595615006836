import React, { useState, useEffect } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";
import { updateFields,formTokko } from '../../redux/contactDucks'

import { ButtonAnimation } from '../buttons/btnWhitRadius/ButtonAnimation';

import { useLocation } from 'react-router-dom';
import arrowRightSvg from '../../assets/images/arrow-left.svg';

export const Form = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const settings = useSelector(store => store.settings)
  const [disabled,setDisabled] = useState(true);
  const [text,setText] = useState('')

  const development = useSelector((store) => store.developments.development).data;
  const property = useSelector((store) => store.properties.property).data;

  const [datos, setDatos] = useState({
    nombre:'',
    apellido:'',
    telefono:'',
    email:'',
    text:'',
  })
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name] : event.target.value, 
    })
  }
  const sendForm = (event) => {
    event.preventDefault()
    if(development){
      dispatch(formTokko('Consulta Emprendimiento',development.name,development.id,true))
    }
    if(property){
      dispatch(formTokko('Consulta Propiedad',property.address,property.id))
    }
    if(!property && !development){
      dispatch(formTokko('Consulta Web',null,null))
    }
    document.getElementById("formContact").reset()
    setText("Gracias por enviarnos tu consulta, en breve te responderemos.")
  }

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  useEffect(() => {
    setText("")
  },[location])

  // const checkTest = () => {
  //   if(development){
  //     console.log("development")
  //   }
  //   if(property){
  //     console.log("property")
  //   }
  //   if(!property && !development){
  //     console.log("contacto")
  //   }
  // }

  return (
    <form className="col-lg-8 d-flex flex-column order-1 order-lg-2" onSubmit={sendForm} id="formContact">
      <div className="row-field row d-flex align-items-baseline">
        <div className="col-field col-12 col-lg-4">
          <div className="form-group">
            <input
              type="text"
              className="form-control nh-roman pb-3"
              name="nombre"
              placeholder="Nombre"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="form-group mb-0">
            <input
              type="text"
              className="form-control nh-roman pb-3"
              name="email"
              required
              placeholder="Email *"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-field col-12 col-lg-4">
          <div className="form-group">
            <input
              type="text"
              className="form-control nh-roman pb-3"
              name="apellido"
              placeholder="Apellido"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="form-group mb-0">
            <input
              type="text"
              className="form-control nh-roman pb-3"
              name="telefono"
              placeholder="Teléfono"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-field col-12 col-lg-4">
          <div className="form-group mb-0">
            <textarea
              className="form-control nh-roman pb-3"
              name="text"
              placeholder="Mensaje"
              onChange={handleInputChange}
              autoComplete="off"
            ></textarea>
            <span className="nh-roman">*Campo obligatorio</span>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 d-flex flex-column flex-lg-row justify-content-end align-items-center">
            <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
            <button className="button-send nh-medium text-uppercase d-flex justify-content-center align-items-center ms-3" type="submit" text="Enviar">
              Enviar
              <img src={arrowRightSvg} className="icon-arrow-right" alt="Arrow right icon" />
            </button>
          </div>
      </div>
      <div>
        <p className="fs-20 montserrat-semibold text-primary-orange">
          { text }
        </p>
      </div>
    </form>
  )
}
