import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import { getLocationProperty,getPrice,searchTour,searchYoutube,get_short_desc,addFavorites, getfavorites} from "../../functions/functions_property";
import { updateFields,formTokko } from '../../redux/contactDucks'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/propertiesDucks'
import {getGroup_CustomTag} from '../../functions/functions_dev'
import {SRLWrapper} from 'simple-react-lightbox'
import "react-lightbox-pack/dist/index.css";
import { useLightbox } from 'simple-react-lightbox'


import Map from "../map";

import dblCircles from '../../assets/images/circles_bg_propiedad.svg';

const Data = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const settings = useSelector((store) => store.settings);
  const property = useSelector((store) => store.properties.property).data;
  const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
	const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);
  const { openLightbox, closeLightbox } = useLightbox()

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};
  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900
    },
    buttons: {
      iconColor: '#ffff'
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase'
    },
    progressBar: {
      height: '20px',
      fillColor: '#191919',
      backgroundColor: 'white'
    }
};

  const [max_caracters, setMax_caracter] = useState(400);
  const [hidden,setHidden] = useState(true)
  const [text,setText] = useState('')
  
  const [datos, setDatos] = useState({
      nombre:'',
      apellido:'',
      email:'',
      text:'',
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  const sendForm = (event) => {
    event.preventDefault()
    dispatch(formTokko('Propiedad',property.fake_address,property.id))
    document.getElementById("formContact").reset()
    setText("¡Mensaje enviado correctamente!")
  }
  const checkActive = (id) => {
    setListFavorites(getfavorites("prop",settings.short_name))
    dispatch(clearFavoritesAction())
    for (const iterator of getfavorites("prop",settings.short_name)) {
        //Por cada uno de los favoritos, traigo su respectiva data de la api
        dispatch(getFavoritesAction(iterator))
    }
  } 
  const getBluePrints = () => {
    if(property.photos.filter(element => element.is_blueprint === true)){
        return (property.photos.filter(element => element.is_blueprint === true))
    }
    return []
  } 

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  var iconSeparate = "| ";

  return property ? (
    <section className="data" id="proyectos">
      <div className="col-10 m-auto">
        <div className="row align-items-start">
          {/* //MOBILE TAGS*/}
          <div className="col-12 tags d-flex d-lg-none align-items-start">
            <div className={"tag nh-roman text-uppercase proyect-bg " + ( property ? 'bg-' + getGroup_CustomTag(property.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') }>
              {property.operations[0].operation_type}
            </div>
            <div className={"tag nh-roman primary-tag ms-3 text-uppercase proyect-color " + ( property ? 'text-' + getGroup_CustomTag(property.development.custom_tags,"Emprendimientos").name.toLowerCase() + ' border-' +  getGroup_CustomTag(property.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') }>
              {property.type.name}
            </div>
          </div>
          {/* ////////////////  */}
          <div className="col-12">
            <div className="row">
              <div className="col-lg-7 ">
                <BreadCrumbs
                  props={[
                    { name: "Eleven", route: "", location: "" },
                    {
                      name: property.real_address,
                      route: "",
                      location: "",
                      active: true,
                    },
                  ]}
                />
                <h2 className="mt-lg-5 pt-lg-3 nh-roman fs-60">
                  {property.real_address}
                </h2>
                <h5 className="m-only nh-roman " style={{marginTop:"2rem"}}>{property.description}</h5>
                <div className="tags mt-5 mb-5 d-none d-lg-flex align-items-start">
                  {property.operations.map((item) =>(
                    <div className={"tag me-2 text-uppercase nh-roman fs-24 proyect-bg " + item.operation_type}>
                      {item.operation_type}
                    </div>
                  ))}
                  <div className={"tag primary-tag ms-0 text-uppercase nh-roman fs-24 proyect-color"}>
                    {property.type.name}
                  </div>
                </div>
                <div className="div_items row mt-5">
                  <div className={"col-6 d-lg-block mt-4 " + (property.room_amount > 0 ? '' : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-prop-type-light me-4   "}></i>
                      {property.room_amount > 0 ? property.room_amount + "  Ambiente" + (property.room_amount > 1 ? 's' : '') : '0 Ambientes'} 
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.suite_amount  > 0 ? "" : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-rooms-light me-4   "}></i>
                      {property.suite_amount  > 0 ? "" + property.suite_amount + '  Habitación' + (property.suite_amount > 1 ? 'es' : '') : "Sin Habitaciones"} 
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.bathroom_amount > 0 ? '' : 'd-none') }>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-bathroom-light me-4"}></i>
                      {property.bathroom_amount > 0 ? "" + property.bathroom_amount + "  Baño" + (property.bathroom_amount > 1 ? 's' : '') : 'Sin Baños' } 
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.parking_lot_amount > 0 ? "" : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-parking-light me-4   "}></i>
                      {property.parking_lot_amount > 0 ? "" + property.parking_lot_amount + '  Cochera' + (property.parking_lot_amount > 1 ? 's' : '')  : 'Sin Cocheras'}
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.toilet_amount > 0 ? '' : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-toilette me-4"}></i>
                      {property.toilet_amount > 0 ? "" + property.toilet_amount + '  Toilettes' + (property.toilet_amount > 1 ? 's' : '')  : 'Sin Toilettes'}
                    </div>
                  </div>
                  <div className="col-6 mt-4 ">
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-expenses me-4   "}></i>
                      {property.expenses > 0 ? "$" + property.expenses + ' Expensas' : 'Sin Expensas'}
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.floors_amount > 0 ? '' : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-floors me-4   "}></i>
                      {property.floors_amount > 0 ? "" + property.floors_amount + ' Planta' + (property.floors_amount > 1 ? 's' : '')  : 'Sin Plantas'}
                    </div>
                  </div>
                  <div className={"col-6 mt-4 " + (property.orientation !== null ? '' : 'd-none')}>
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-orientation me-4   "}></i>
                      {property.orientation !== '' ? 'Orientación: ' + property.orientation : 'Sin Orientación'}
                    </div>
                  </div>
                  <div className="col-6 mt-4 ">
                    <div className="item-extra d-flex  fs-7">
                      <i className={"icon-age me-4   "}></i>
                      {property.age === 0 ? 'A estrenar' : property.age === -1 ? 'En construcción' : + property.age + ' años de antiguedad'}
                    </div>
                  </div>
                </div>
                {/* SUPERFICIES */}
                  <div className="row mt-5">
                    <div className=" pt-lg-5 d-flex align-items-center col-6">
                      <div className="item-extra  surface d-flex ">
                        <i className="icon-measure me-4 ms-2 ms-lg-0"></i>
                        {property.total_surface > 0? new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales " : '0 m2 totales.'}
                      </div>
                    </div>
                    <div className="pt-lg-5 d-flex align-items-center col-6">
                      <div className="item-extra surface d-flex">
                        <i className="icon-measure me-4 ms-2 ms-lg-0"></i>
                        {property.roofed_surface > 0? "" + new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos " : '0 m2 cubiertos.'} 
                      </div>
                    </div>
                  </div>
                {/* SUPERFICIES */}
              </div>
              <div className={"col-lg-5 price-container"}>
                <div className={"content-description d-flex flex-column align-items-start"}>
                  <p className="text-content nh-roman fs-23">
                    {get_short_desc(property.description, max_caracters).map((element) => (
                      <>
                        {element}
                        <br />
                      </>
                    ))}
                    <a
                      className="cursor-pointer nh-roman d-block pt-5 fs-20 see_description text-white"
                      onClick={() =>
                        max_caracters === 400
                          ? setMax_caracter(9999)
                          : setMax_caracter(400)
                      }
                    >
                      {property.description.length > 400 ?
                      max_caracters === 400 ? 
                          "Ver más"
                        : "Ver menos"
                        : ""}
                    </a>
                  </p>
                  <div className="content-price justify-content-center justify-content-lg-start mt-auto">
                    {property.operations.map((item)=>(
                      <h3 className={`nh-light fs-50 ${item.operation_type + (property.web_price ? '' : ' min-size')}`}>{property.web_price ? getPrice(item.prices[0]) : 'Precio a consultar'}</h3>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* //DESKTOP TAGS*/}
          <div className="mt-5"></div>
          <div className="col-lg-12 mt-lg-5 pt-5d-lg-block m-auto">
            <div className="row d-flex justify-content-start align-items-center">
              {property.tags.map((item, index) => (
                <div className="col-lg-3 col-6 mb-3">
                  <div className="item align-items-center d-flex">
                    <div className={"tick d-flex justify-content-center align-items-center me-lg-3 me-1"}>
                      <i className="icon-tick"></i>
                    </div>
                    <p className="nh-roman fs-24 m-0">{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* ///////////////// */}
        </div>
      </div>
      {/* Planos */}
      {getBluePrints().length > 0 ? 
      <section className="section col-12 m-auto position-relative" style={{textAlign: "center"}} id="planos">
          <div style={{textAlign: "center"}}>
              <h2 className="plano-mobile nh-medium fs-50" style={{zIndex:"99",position:"relative",display:"block",marginTop:"3em",marginBottom:"0rem"}}>Planos</h2>
          </div>
          <div class="js-projects-gallery">
            <section class="clients section position-relative">
              <div class="images-grid-section">
                    <SRLWrapper options={options}>
                      <div class="project one image-grid-element">
                          {
                            getBluePrints().map((element,index) => (
                              <a className="cursor-pointer project-plano" onClick={() => openLightbox(index)}>
                                <figure style={{margin:"0"}}>
                                    <img alt="" src={element.original}  />
                                    <figcaption>
                                      <div class={"project-zoom " + ( property ? ' bg-' + getGroup_CustomTag(property.development.custom_tags,"Emprendimientos").name.toLowerCase() : '')}></div>
                                      <h4 class="project-category">
                                        {element.description}
                                      </h4>
                                    </figcaption>
                                </figure>
                              </a>
                            ))
                          }
                      </div>      
                    </SRLWrapper>
              </div>              
            </section>
        </div>
      </section>
      :''}
      {/* Mapa */}
      <section className="location-wrapper text-center" id="ubicacion">
          <div className="text-center">
              <div className="container title-container position-relative">
                  <h2 className="nh-medium section-title">Ubicación</h2>
              </div>
              <h3 className="nh-roman">{property.fake_address ? (property.fake_address +  " | " + property.location.short_location.split(" | ")[1]) + "." : ''}</h3>
          </div>
          <div className="prop-map">
              <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
          </div>
      </section>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};
export default Data;
