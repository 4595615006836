import React from 'react'

import { Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { changeLocationAction } from "../../redux/themeDucks";
import { updateFields,formTokko } from '../../redux/contactDucks'
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useHistory } from "react-router-dom";
import $ from 'jquery'
import {SectionForm} from '../section-form/SectionForm'
export const Footer = () => {
  function getYear() {
    const data = new Date;
    return(data.getFullYear())
  }
  return (
    <>
    <SectionForm />
    <footer className="footer d-lg-flex justify-content-center p-4 ">
      <div className="col-lg-10 m-auto">
        <div class="copy mt-auto mt-lg-3 text-center"><span className="media-text">Design by <a class="mediahaus-link" target="_blank" href="https://mediahaus.com.ar/">MediaHaus</a>  - Powered by <a class="mediacore-link" href="http://mediacore.com.ar/app">MediaCore</a></span><br /><span className="text-small">Todos los derechos reservados ® 2022</span></div>
        </div>
    </footer>
    </>
  )
}
