import React from 'react'
// import arrowTop from '../../assets/images/arrow_up.svg'; 
// import elipse_bg from '../../assets/images/elipse_bg.svg'
import { Form } from '../form/Form'
import Map from '../map'
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import {useLocation} from "react-router-dom";
import arrowTopSvg from '../../assets/images/arrow-left.svg';

const latitud = {latitud: -34.57565921221931, longitud: -58.420479848902474}

export const SectionForm = () => {
  const { data } = useSelector((store) => store.developments.development);
  const unity = useSelector((store) => store.properties.property).data;
  const settings  = useSelector((store) => store.settings);
  const location = useLocation();

  const checkView = () => {
    if(location.pathname.split(settings.base_url).length === 1){
      return false
    }
    if(location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo' || location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      return true
    } else {
      return false
    }
  }

  const [name,setName] = useState('');

  useEffect(() => {
    if(data && location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo'){
      setName(data.name.replace(/ /g, "-").toLowerCase())
    }
    else if(unity && location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      setName(unity.development.name.replace(/ /g, "-").toLowerCase())
    }
    else{
      setName('')
    }
  },[location,data,unity])

  

  return  (
    <section id="contacto" className={"section__five " + name }>
      <div className="col-10 m-auto d-flex flex-column justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-center align-items-center position-relative w-100 title-container position-relative">
          <h2 className="nh-medium section-title">¿Queres más información?</h2>
        </div>
        <div className="row section__five__container flex-lg-row flex-column align-items-lg-baseline align-items-center justify-content-center w-100">
          <div className="col-lg-4 my-lg-5 my-3 d-flex align-items-center flex-column justify-content-center justify-content-lg-start align-items-lg-start order-2 order-lg-1">
            <ul className="contact mb-0">
              <li className="contact__item nh-light">
                {settings.location.address}
              </li>
              <li className="contact__item nh-light">
                {settings.location.region}
              </li>
              <li className="contact__item nh-light">
                <a href={"https://web.whatsapp.com/send?&phone=" + settings.contact.whatsapp.link}>
                  Wa. {settings.contact.whatsapp.visual}
                </a>
              </li>
            </ul>
            <a href={"mailto:" + settings.contact.mail} className="contact__item nh-light">{settings.contact.mail}</a>
          </div>
          <Form />
          <div className="col-12 d-flex justify-content-end order-3">
            <a onClick={() => window.scrollTo( 0, 0 )}  className="section__five__arrow__top d-flex justify-content-center align-items-center">
              <img src={arrowTopSvg} className="icon-arrow-top" alt="Arrow top icon" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
