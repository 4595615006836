import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
// import SimpleReactLightbox from 'simple-react-lightbox';
// import './../node_modules/animate.css/animate.css';
import './assets/icons/icomoon/style.css';
import './assets/styles/index.scss';
import './assets/styles/App.scss';
import {BrowserRouter as Router,Switch,withRouter,Route} from "react-router-dom";
import { Emprendimiento } from './components/pages/emprendimiento/Emprendimiento.js';
import { Provider } from 'react-redux';
import generateStore from './redux/store.js';

import SimpleReactLightbox from 'simple-react-lightbox'


ReactDOM.render(
    <Router>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Router>,
  document.getElementById('root')
);

const store = generateStore()

// ReactDOM.render(
//   <Provider store={store}>
//       <Emprendimiento />
//    </Provider>,
//    document.getElementById('root')
//  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
