// constantes
const dataInicial = {
    name:'Eleven',
    short_name:'eleven',
    // base_url:'/clientes/red-react/',
    base_url:'/',
    // client_id:31,
    social:
    {
        facebook:'https://es-la.facebook.com/manciniprop/',
        instagram:'https://www.instagram.com/manciniprop/',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['' , '000-000000'],
        whatsapp:{link:'+54911 3427-3094',visual:'11 3427 3094'},
        mail:'info@mancini.com.ar'
    },
    location:
    {
        address:'Ricardo Balbín 6608',
        region:'Gral. San Martín - G.B.A. Zona Norte',
        cp:'0000'
    },
    keys:{
        captcha:'6Lel-rodAAAAAH3vs9192XBf4sd_bSWNAjtqUX53',
        tokko:'2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b',
        google_tgm:'',
        google_map:'AIzaSyCelKXL-cdJKcw8uWLWDGFrL6tZFASQ5uY',
    },
    seo:{
        title:'Eleven - Un desarrollo de Mancini Propiedades',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}