import React , {useEffect} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentAction } from '../redux/developmentsDucks';
import { getUnitsDevelopmentAction } from '../redux/developmentsDucks';
import {clearPropertyAction} from '../redux/propertiesDucks'
import {get_shortDescription, getGroup_CustomTag} from '../functions/functions_dev'


import Main from '../components/emprendimiento/main'
import Data from '../components/emprendimiento/data'
import Projects from '../components/emprendimiento/projects'
import Details from '../components/emprendimiento/details'
import Video from '../components/emprendimiento/video'
import Unities from '../components/emprendimiento/unities'
import Contact from '../components/emprendimiento/contact'
import Location from '../components/emprendimiento/location'
import { Whatsapp } from '../components/Whatsapp'

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Emprendimiento(){
    const dispatch = useDispatch();
    // const {id} = useParams();
    
    // Replaced id by params with id from tokko
    const id = 34529; 
    
    useEffect(() => {
        const getDev = () => {
            dispatch(getDevelopmentAction(id))
            dispatch(getUnitsDevelopmentAction(id))
            dispatch(clearPropertyAction())
        }
        getDev()
    }, [])
        
    ScrollToTopOnMount()
    const { data } = useSelector((store) => store.developments.development);

    return(
        <div className={"Emprendimiento " + ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')}>
             <div className="layout">
                <Main />
                <Whatsapp />
                <div className="content">
                    <Data />
                    <Projects />
                    <Details />
                    <Video />
                    <Unities />
                    <Location />
                    {/* <Contact /> */}
                </div>
            </div>
        </div>
    )
}

export default Emprendimiento
