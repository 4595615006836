import { data } from 'jquery'
import { useSelector } from 'react-redux';
import Map from '../map'
const Location = () => {
    const {data} = useSelector((store) => store.developments.development);

    return data ? (
        <>
            <section className="location-wrapper text-center" id="ubicacion">
                <div className="text-center">
                    <div className="container title-container position-relative">
                        <h2 className="nh-medium section-title">Ubicación</h2>
                    </div>
                    <h3 className="nh-roman">{data.fake_address ? (data.fake_address +  " | " + data.location.short_location.split(" | ")[1]) + "." : ''}</h3>
                </div>
                <div className="dev-map">
                    <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />
                </div>
            </section>
        </>
    )
    :
    ''

}

export default Location