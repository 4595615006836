import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag} from '../../functions/functions_dev'
import BreadCrumbs from "../breadcrumbs";
import arrowLeftSvg from '../../assets/images/arrow-left.svg';
import elevenLogoSvg from '../../assets/images/eleven-logo.svg';

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        empresa:'',
        phone:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      // dispatch(formTokko('Contacto',null))
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
  
    const getLastFile = (development) => {
      return development?.files[development?.files.length - 1];
    }

  // Render
  return data ? (
    <section class={"section wrapper " + ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')} id="proyectos">
      <div class="container ">
          <div class="lading-project-about">
              <div className="mx-3 mb-5 mb-lg-0 pe-lg-5">
                  <h3 class="nh-medium mb-5 text-uppercase title text-center text-lg-start">Información:</h3>
                  <p className="nh-light fs-24 mt-4 nh-light">{get_shortDescription(data.description).description}</p>
                  {
                    (data.files.length > 0 ?
                    <a target="_blank" href={data.files[data.files.length - 1].file} style={{display:"flex",alignItems:"center"}} className={"btn " +( data ? 'bg-' + getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '')}><i style={{fontSize: "1.1rem",marginRight:".5rem"}} className="icon-download"></i> Descargar presentación</a>
                    :''
                  )}
              </div>
              <div className="mx-3 mb-5 mb-lg-0 ps-lg-5 ms-lg-5">
                  <h3 class="nh-medium mb-5 text-uppercase title text-center text-lg-start">Características:</h3>
                  <div className="amenities-mobile mt-5 pt-1">
                      <div className="item-amenities">
                        <div className="tick d-flex justify-content-center align-items-center me-lg-3 me-1">
                          <i className="icon-tick"></i>
                        </div>
                        <h4 className="ms-3 text-uppercase nh-medium">11 Pisos</h4>
                      </div>
                      <div className="item-amenities">
                        <div className="tick d-flex justify-content-center align-items-center me-lg-3 me-1">
                          <i className="icon-tick"></i>
                        </div>
                        <h4 className="ms-3 text-uppercase nh-medium">77 Unidades</h4>
                      </div>
                      <div className="item-amenities">
                        <div className="tick d-flex justify-content-center align-items-center me-lg-3 me-1">
                          <i className="icon-tick"></i>
                        </div>
                        <h4 className="ms-3 text-uppercase nh-medium">44 Cocheras</h4>
                      </div>
                      <div className="item-amenities">
                        <div className="tick d-flex justify-content-center align-items-center me-lg-3 me-1">
                          <i className="icon-tick"></i>
                        </div>
                        <h4 className="ms-3 text-uppercase nh-medium">6 Locales</h4>
                      </div>
                  </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-center">
                      <img src={elevenLogoSvg} alt="Eleven Logo" className="mb-4 eleven-logo"/>
                      { (getLastFile(data)?.file !== undefined) ? <a href={getLastFile(data)?.file} target="_blank"
                          className="cta-button nh-medium text-uppercase d-flex justify-content-center align-items-center" 
                          onClick={(e) => e.preventDefault()} role="button">
                          Descargar brochure
                          <img src={arrowLeftSvg} className="icon-arrow-bottom" alt="Arrow bottom icon" />
                      </a> : ''}
              </div>
          </div>
      </div>
</section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
